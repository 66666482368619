import React, { useState, useEffect, createContext, useContext } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import PageHome from './pages/PageHome';
import PageClientLogin from './pages/PageClientLogin';
import Header from './global/Header';

export const AppContext = createContext();

export default function App() {


  const [user, setUser] = useState('')

  useEffect(() => {
     const foundUser = window.localStorage.getItem("user");
     if(foundUser){
        
      try {
        const userData = JSON.parse(foundUser);
        setUser(prevUser => {
          const currentUser = { ...prevUser, loggedIn: true };
          // Loop through keys in userData and set them in currentUser
          for (const key in userData) {
            currentUser[key] = userData[key];
          }
          return currentUser;
        });
        } catch (error) {
          console.error("Failed to parse user data:", error);
      }

     }

  }, [])


  return (
    <div className="App">
        <AppContext.Provider value={{ user, setUser }}>
        <Header />
      <div className="main">
        <Routes>
          <Route exact path="/" element={<PageHome />}/>
          <Route exact path="/client-login" element={<PageClientLogin />}/>
        </Routes>
      </div>
      </AppContext.Provider>
    </div>
  );
}

export const useAppContext = () => {
  return useContext(AppContext);
};

