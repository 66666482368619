import React from 'react'
import { Link } from 'react-router-dom';
import styles from '../stylesheets/header.module.css';
import logo from '../assets/site-logo.png';  // Adjust path as needed
import { useAppContext } from '../App';

export default function Header() {

  const { user, setUser } = useAppContext();

  const handleLogout = () =>{
    localStorage.removeItem('user'); 
    setUser('');
    window.location = '/'; 
  }

  
  return (
    <div className={styles['header']}>
      <div className={styles['container']}>

        <div className={styles['main']}>
      
        
        <div className={styles['column']}>
        <Link to="/">
        <img src={logo} alt="Logo" style={{ width: '30px', height: 'auto' }} />
        </Link>
        </div>

        <nav>
        <Link className={styles['link']} to="/">Home</Link>   
        </nav>

        <div className={styles['column']}> 
        {!user ? (
        <Link className={styles['link']} to="/client-login">Client Login</Link>    
        ) : (
        <button 
        onClick={handleLogout}
        className={styles['link']}>Logout</button>  
        )}
        </div>
        
        </div>
       
        {user && (
          <div className={styles['logged-in']}>
            Logged in as,
            <div className={styles['username']}>{user.username}</div>
          </div>
        )}
        

    
        </div>
      
    </div>
  )
}
