import React, { useState } from 'react'
import styles from '../stylesheets/page-login.module.css';
import axios from 'axios';

export default function PageClientLogin() {

    const [form, setForm] = useState({
        username: '',
        password: ''
    })
    const [error, setError] = useState(false)

    const handleLogin = async (event) => {
        event.preventDefault()
        setError(false)

        if(form.username === ''){
          setError({'username':'Username is empty'})
          return false;
        }

        if(form.password === ''){
          setError({'password':'Please enter a password'})
          return false;
        }
       
            try {
          
              const requestOptions = {
                method: "POST",
                url: `/api/user/client-login`,
                withCredentials: true,
                data: form 
              }
              
              const response = await axios(requestOptions);
              const data = response.data;
              
              if(!data.hasOwnProperty('error')) {
                  
                if (typeof window !== 'undefined' && data) { // Ensure it's running in the browser and data is available
                    const { id, username, request_token } = data['user'];
                    const userData = { id, username, request_token };
                    if(!localStorage.getItem('user')) {
                        localStorage.setItem('user', JSON.stringify(userData));    
                        window.location.href = `/client/${username}`;  
                    }
                }

              }else if(data.hasOwnProperty('error')) {
                setError(data);
              }
              
            } catch (error) {
                 console.log('Error',error)
            }
          
    }

    const handleInput = (event) =>{
        const name = event.target.name;
        const value = event.target.value;

        if(error.hasOwnProperty(name)){
          setError(false)
        }


        setForm(prev =>({
          ...prev,
          [name]: value
        }))
    }

  return (
    <div className={styles['container']}>
        <h5>Client Login</h5>
        <form className={styles['client-login']}>
           <input
           name="username" 
           placeholder="Username"
           type="text"
           value={form.username} 
           onChange={handleInput}
           className={`${styles['input']} ${error.hasOwnProperty('username') && styles['error-username']}`}
           />
           <input 
           name="password"
           placeholder="Password"
           type="password"
           value={form.password} 
           onChange={handleInput} 
           className={`${styles['input']} ${error.hasOwnProperty('password') && styles['error-password']}`}
           />
           <button onClick={handleLogin}>Login</button>
           <div className={styles['error']}>
             {Object.keys(error).map(key => (
              <div key={key}>{error[key]}</div>
            ))}
            </div>
        </form>
    </div>
  )
}
